import { ApolloQueryResult } from '@apollo/client';
import {
	Entity,
	Language,
	NationalRosterYear,
	News,
	NewsQueries,
	PaginationByPage,
	Position,
	SportId,
	StatCareer,
	StatCareerList,
	StatPlayer,
	StatPlayerCareer,
	StatQuery,
	StatRankingAttribute,
	StatRankingTeamAttribute,
	StatSeason,
	StatSourceList,
	StatTeam,
	StatTournament,
	Tag,
	TagIdType,
	TagQueries,
	ContentType,
} from '@ui-kit/main-api-types';
import { LANG } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/types';

import {
	changeGateway as changeGatewayV2,
	client,
	initializeApollo,
} from '@apollo-client-v2';
import {
	IGetPersonStatBySeason,
	IGetTagMainInfo,
	IGetTeamSqud,
} from '@store/tag/types';
import {
	IGetPersonCurrentSeasonMatches,
	IGetPersonStatPageInfo,
} from '@store/tag_unification/types';
import { useUserStore } from '@store/user';
import { TRANSFERS_TAG_ID_UNIFICATED } from '@utils/constants';
import { addIfArr, addIfObj } from '@utils/helpers';
import { TeamType } from '@utils/types';

import queries from './queries';

interface IGetLeagueTransfersForWindows {
	id: string;
	year: number;
	window: string;
	locale: LANG;
}
interface IGetCustomTabs {
	tagId: string;
	tabHru: string;
	locale?: string;
}
interface IGetSeason {
	id: string;
	locale?: string;
}

export const getLeagueTransfersForWindows = async ({
	id,
	year,
	window,
	locale,
}: IGetLeagueTransfersForWindows): Promise<StatTournament> => {
	const tagQueryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getLeagueTransfersForWindows,
			variables: {
				id,
				year,
				window,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return tagQueryResult?.data?.stat?.football?.stat_tournament;
};

interface IGetTopLeaguesTransferWindows {
	locale: LANG;
}

export const getTopLeaguesTransferWindows = async ({
	locale,
}: IGetTopLeaguesTransferWindows): Promise<StatTournament[]> => {
	const tagQueryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getTopLeaguesTransferWindows,
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return tagQueryResult?.data?.stat?.football?.stat_topLeagues;
};

export const getCustomTabs = async ({
	tagId,
	tabHru,
	locale,
}: IGetCustomTabs): Promise<TagQueries> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getCustomTabs,
			variables: {
				tagId,
				tabHru,
				lang: locale?.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});
	return tagQueryResult?.data?.tagQueries;
};

export const getPersonCareerPageInfo = async ({
	id,
	tagIdType = TagIdType.Hru,
	locale,
}: IGetTagMainInfo): Promise<Tag> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNewV2.query({
			query: queries.getPersonCareerPageInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});
	return tagQueryResult?.data?.tagQueries?.get;
};

export const getPersonStatBySeason = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
	seasonShortName,
}: IGetPersonStatBySeason): Promise<StatPlayerCareer[]> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const personStatBySeasonResult: ApolloQueryResult<{
		tagQueries: TagQueries;
	}> = await clientNewV2.query({
		query: queries.getPersonStatBySeason,
		variables: {
			id,
			tagIdType,
			season: seasonShortName,
		},
		context: {
			headers: {
				'X-Language': locale?.toLowerCase(),
			},
		},
		errorPolicy: 'all',
	});

	return (
		personStatBySeasonResult?.data?.tagQueries?.get?.statObject as StatPlayer
	)?.stat;
};

export const getPersonStatPageInfo = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
}: IGetPersonStatPageInfo): Promise<Tag> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getPersonStatPageInfo,
			variables: {
				id,
				tagIdType,
				locale: locale.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries?.get;
};

export const getPersonCurrentSeasonMatchesData = async ({
	id,
	source = StatSourceList.Global,
	seasonIds,
	locale,
}: IGetPersonCurrentSeasonMatches): Promise<StatPlayer> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getPersonCurrentSeasonMatchesData,
			variables: {
				id,
				source,
				seasonIds,
			},
			errorPolicy: 'all',
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return queryResult?.data?.stat?.football?.stat_player;
};

export const getLeagueStatTransfers = async ({
	id,
	input,
	currentPage = 1,
	pagesize = 25,
	locale,
}): Promise<{
	transfers: StatCareer[];
	seasons: StatSeason[];
	hasNextPage: boolean;
}> => {
	const queryResult = await client.query({
		query: queries.getLeagueStatTransfers,
		variables: {
			id,
			input: {
				currentPage,
				pagesize,
				...input,
			},
		},
		context: {
			headers: {
				'X-Language': locale?.toLowerCase(),
			},
		},
		errorPolicy: 'all',
	});

	const statObject = queryResult?.data?.stat?.football
		?.stat_tournament as StatTournament;

	return {
		hasNextPage: statObject?.careers?.hasNextPage || null,
		transfers: statObject?.careers?.list || null,
		seasons: statObject?.seasons || null,
	};
};

interface IGetTeamMainInfo {
	hru: string;
	locale: LANG;
}

export const getTeamMainInfo = async ({
	hru,
	locale,
}: IGetTeamMainInfo): Promise<Tag> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamMainInfo,
			variables: {
				hru,
				locale: locale?.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});
	return tagQueryResult?.data?.tagQueries?.get;
};

export const getTeamTransfersPageInfo = async ({
	hru,
	locale,
}: IGetTeamMainInfo): Promise<Tag> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamTransfersPageInfo,
			variables: {
				hru,
				locale: locale.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries?.get;
};

export interface IGetTeamStatId {
	hru: string;
}

export const getTeamStatId = async ({
	hru,
}: IGetTeamStatId): Promise<string> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamStatId,
			variables: {
				hru,
			},
			errorPolicy: 'all',
		});

	return tagQueryResult?.data?.tagQueries?.get?.statObject?.id;
};

interface IGetTeamMainAndAboutTeamWidgetInfo {
	hru: string;
	locale: LANG;
	statTeamId: string[];
}

export const getTeamMainAndAboutTeamWidgetInfo = async ({
	hru,
	locale,
	statTeamId,
}: IGetTeamMainAndAboutTeamWidgetInfo): Promise<TagQueries> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamMainAndAboutTeamWidgetInfo,
			variables: {
				hru,
				statTeamId,
				locale: locale.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries;
};

export const getTeamStatTransfers = async ({
	id,
	input,
	currentPage = 1,
	pagesize = 25,
	locale,
}): Promise<StatCareerList> => {
	const queryResult = await client.query({
		query: queries.getTeamStatTransfers,
		variables: {
			id,
			input: {
				currentPage,
				pagesize,
				...input,
			},
		},
		context: {
			headers: {
				'X-Language': locale?.toLowerCase(),
			},
		},
		errorPolicy: 'all',
	});

	return queryResult?.data?.stat?.football?.stat_team?.careers;
};

export const getTournamentMainInfo = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
}: IGetTagMainInfo): Promise<TagQueries> => {
	const clientNew = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNew.query({
			query: queries.getTournamentMainInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries;
};

export const getTournamentTransfersPageInfo = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
}: IGetTagMainInfo): Promise<TagQueries> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNewV2.query({
			query: queries.getTournamentTransfersPageInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries;
};

export const getTournamentMainInfoMainPage = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
}: IGetTagMainInfo): Promise<TagQueries> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNewV2.query({
			query: queries.getTournamentMainInfoMainPage,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});

	return tagQueryResult?.data?.tagQueries;
};

export const getPersonMainInfo = async ({
	id,
	tagIdType = TagIdType.Hru,
	locale,
}: IGetTagMainInfo): Promise<Tag> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNewV2.query({
			query: queries.getPersonMainInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});
	return tagQueryResult?.data?.tagQueries?.get;
};

export const getPersonMainPageInfo = async ({
	id,
	tagIdType = TagIdType.Hru,
	locale,
}: IGetTagMainInfo): Promise<TagQueries> => {
	const clientNewV2 = changeGatewayV2({
		lang: locale?.toLowerCase(),
		appname: '',
	});
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await clientNewV2.query({
			query: queries.getPersonMainPageInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
		});
	return tagQueryResult?.data?.tagQueries;
};

export const getTeamsSquad = async ({
	id,
	locale,
	seasonId,
	year,
	errorPolicy = 'all',
}: IGetTeamSqud): Promise<Tag> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamsSquad,
			variables: {
				id,
				locale: locale.toUpperCase() as Language,
				tagIdType: TagIdType.Hru,
				seasonId,
				year,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy,
		});

	return tagQueryResult?.data?.tagQueries?.get;
};

export const getTeamsSeasons = async ({
	id,
	locale,
	errorPolicy = 'all',
}: IGetTagMainInfo): Promise<{
	seasons: StatSeason[] | NationalRosterYear[];
	isNationalTeam: boolean;
}> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamsSeasons,
			variables: {
				id,
				tagIdType: TagIdType.Hru,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy,
		});

	const statObject = tagQueryResult?.data?.tagQueries?.get
		?.statObject as StatTeam;

	const isNationalTeam = statObject?.type === TeamType.NATIONAL;

	const seasons = [];
	const teamSeasons = statObject?.teamSeasons;
	if (isNationalTeam) {
		seasons.push(...statObject?.rosterYears);
	} else {
		for (let i = 0; i < teamSeasons?.length; i++) {
			if (teamSeasons?.[i]?.seasons?.length) {
				seasons.push(teamSeasons?.[i]?.seasons?.[0]);
			}
		}
	}

	return {
		seasons,
		isNationalTeam,
	};
};

export const getTeamSquadStatSeasonsAndInfo = async ({
	id,
	tagIdType = TagIdType.Hru,
	locale,
	errorPolicy = 'all',
}: IGetTagMainInfo): Promise<Tag> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getTeamSquadStatSeasonsAndInfo,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy,
		});

	return tagQueryResult?.data?.tagQueries?.get;
};

export const getBestPlayers = async ({
	selectedTournamentId,
	locale,
	teamID,
	errorPolicy = 'all',
}: {
	selectedTournamentId: string;
	locale: string;
	teamID?: string;
	errorPolicy?: string;
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestPlayers,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy,
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const getBestPlayersByMetric = async ({
	selectedTournamentId,
	locale,
	attribute,
	amplua,
	teamID,
}: {
	selectedTournamentId: string;
	locale: string;
	attribute: StatRankingAttribute[];
	amplua?: Position[];
	teamID?: string;
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestPlayersByMetric,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
				attribute,
				...(amplua && { amplua }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const getBestPlayersByMetricAveragePerGame = async ({
	selectedTournamentId,
	locale,
	attribute,
	amplua,
	teamID,
}: {
	selectedTournamentId: string;
	locale: string;
	attribute: StatRankingAttribute[];
	amplua?: Position[];
	teamID?: string;
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestPlayersByMetricAveragePerGame,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
				attribute,
				...(amplua && { amplua }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const getTeamSquadStat = async ({
	selectedTournamentId,
	teamID,
	locale,
}: {
	selectedTournamentId: string;
	teamID: string;
	locale: string;
}): Promise<{
	theBestTeamStatSeason: StatSeason[];
	squadWithStat: StatTeam;
}> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getTeamSquadStat,
			variables: {
				statSeasonID: selectedTournamentId,
				statTeamID: teamID,
				teamID,
				seasonID: selectedTournamentId,
				tagIdType: TagIdType.Hru,
				locale: locale.toUpperCase() as Language,
				idTeamString: teamID,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return {
		theBestTeamStatSeason: queryResult?.data?.stat?.football?.stat_season || [],
		squadWithStat: queryResult?.data?.stat?.football?.stat_team,
	};
};

export const getSeasonOnlyWithBrackets = async ({
	id,
	locale,
}: IGetSeason): Promise<StatSeason> => {
	const seasonQueryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getSeasonOnlyWithBrackets,
			variables: {
				id,
				locale: locale.toUpperCase(),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return seasonQueryResult?.data?.stat?.football?.stat_season?.[0];
};

interface IGetTagsNews {
	tagId: string;
	tagType?: TagIdType;
	locale: string;
	pageNumber?: number;
	pageSize?: number;
	appName?: string;
	isItTransfersPage?: boolean;
	errorPolicy?: string;
}

export const getTagsNews = async ({
	tagId,
	tagType = null,
	locale,
	pageNumber = null,
	pageSize = null,
	appName = '',
	isItTransfersPage = false,
	errorPolicy = '',
}: IGetTagsNews): Promise<{
	newsList: News[];
	lastPage?: number;
	errMessage?: string;
}> => {
	const queryResult: ApolloQueryResult<{
		newsQueries: NewsQueries;
	}> = await client.query({
		query: queries.getTagsRelatedNews,
		variables: {
			tag: {
				ids: [
					tagId,
					...addIfArr(isItTransfersPage, [TRANSFERS_TAG_ID_UNIFICATED]),
				],
				idType: tagType || (+tagId ? TagIdType.Tag : TagIdType.Hru),
			},
			...addIfObj(!!pageNumber, { page: pageNumber }),
			pageSize: pageSize || 40,
			lang: locale?.toUpperCase(),
		},
		context: {
			headers: {
				'x-language': locale?.toLowerCase(),
				...addIfObj(!!appName, { 'x-tribuna-app': appName }),
			},
		},
		...addIfObj(!!errorPolicy, { errorPolicy }),
	});

	const newsData = queryResult?.data?.newsQueries?.feed;

	return {
		newsList: newsData?.items || [],
		lastPage: (newsData?.paginationInfo as PaginationByPage)?.lastPage || null,
		errMessage: queryResult?.errors?.[0]?.message || '',
	};
};

export const getLeagueSeasons = async ({
	id,
	tagIdType = TagIdType.Tag,
	locale,
}: IGetTagMainInfo): Promise<TagQueries> => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getLeagueSeasons,
			variables: {
				id,
				tagIdType,
				locale: locale?.toUpperCase() as Language,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return tagQueryResult?.data?.tagQueries;
};

export const getBestTeams = async ({
	selectedTournamentId,
	locale,
	teamID,
}: {
	selectedTournamentId: string;
	locale: string;
	teamID?: string;
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestTeams,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const getBestTeamsByMetric = async ({
	selectedTournamentId,
	teamID,
	locale,
	attribute,
	amplua,
}: {
	selectedTournamentId: string;
	teamID?: string;
	locale: string;
	attribute: StatRankingTeamAttribute[];
	amplua?: Position[];
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestTeamsByMetric,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
				attribute,
				...(amplua && { amplua }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const getBestTeamsByMetricAveragePerGame = async ({
	selectedTournamentId,
	teamID,
	locale,
	attribute,
	amplua,
}: {
	selectedTournamentId: string;
	teamID?: string;
	locale: string;
	attribute: StatRankingTeamAttribute[];
	amplua?: Position[];
}): Promise<StatSeason[]> => {
	const queryResult: ApolloQueryResult<{ stat: StatQuery }> =
		await client.query({
			query: queries.getBestTeamsByMetricAveragePerGame,
			variables: {
				statSeasonID: selectedTournamentId,
				...(teamID && { teamID: [teamID] }),
				attribute,
				...(amplua && { amplua }),
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
				},
			},
			errorPolicy: 'all',
		});

	return queryResult?.data?.stat?.football?.stat_season || [];
};

export const searchTagByName = (pattern, sort) => {
	const { authToken } = useUserStore.getState().user;
	const client = initializeApollo();

	return client.query({
		query: queries.searchByTagName,
		variables: {
			pattern,
			sort,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};
interface IGetContentList {
	page?: number;
	appName?: string;
	errorPolicy?: string;
	tagId?: string;
	locale?: string;
	idType?: TagIdType;
	contentTypes?: Array<ContentType>;
	sport?: Array<SportId>;
	main?: boolean;
	mainInSection?: boolean;
	pageSize?: number;
}

export const getContentList = async ({
	locale,
	tagId = '',
	page = 1,
	pageSize = 40,
	appName,
	contentTypes,
	sport,
	main,
	mainInSection,
	errorPolicy = '',
}: IGetContentList): Promise<{
	contentList: Entity[];
	paginationInfo: PaginationByPage;
	errMessage?: string;
}> => {
	const userState = useUserStore.getState().user;

	const queryResult = await client.query({
		query: queries.getContentForMainPage,
		variables: {
			...addIfObj(!!tagId, {
				tag: {
					ids: [tagId],
					idType: +tagId ? TagIdType.Tag : TagIdType.Hru,
				},
			}),
			page,
			pageSize,
			languages: [locale.toUpperCase()],
			lang: locale?.toUpperCase(),
			contentTypes: contentTypes,
			sport: sport,
			main,
			mainInSection,
		},
		context: {
			headers: {
				'X-Language': locale?.toLowerCase(),
				'x-auth-token': userState.authToken,
				'x-device-id': userState.deviceId,
				...addIfObj(!!appName, { 'x-tribuna-app': appName }),
			},
		},
		...addIfObj(!!errorPolicy, { errorPolicy }),
	});

	const tagContent = queryResult?.data?.feedQueries;
	const contentList = tagContent?.feedPAL?.items || null;
	const paginationInfo = tagContent?.feedPAL?.paginationInfo || null;

	return {
		contentList,
		paginationInfo,
		errMessage: queryResult?.errors?.[0]?.message || '',
	};
};

export const getIgamingContentPageInfo = async ({
	id,
	locale,
}: IGetTagMainInfo) => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getIgamingContentPageInfo,
			variables: {
				id: id,
				locale: locale.toUpperCase(),
			},
		});

	return tagQueryResult?.data?.tagQueries?.get;
};

export const getIgamingMainPageInfo = async ({
	id,
	locale,
}: IGetTagMainInfo) => {
	const tagQueryResult: ApolloQueryResult<{ tagQueries: TagQueries }> =
		await client.query({
			query: queries.getIgamingMainPageInfo,
			variables: {
				id: id,
				locale: locale.toUpperCase(),
			},
		});

	return tagQueryResult?.data?.tagQueries?.get;
};
